import { useCallback, useContext, useEffect, useState } from "react";
import api from "../../api";
import { LocationContext } from "../../contexts";
import { useResolver } from "../../hooks";
import {
  Location,
  Provider,
  ProviderCreateParams,
  ProviderMeta,
  ProviderUpdateParams,
} from "../../types";
import Alert from "../../ui/Alert";
import Button from "../../ui/Button";
import SchemaFields from "../../ui/form/SchemaFields";
import TextInput from "../../ui/form/TextInput";
import RadioInput from "../../ui/form/RadioInput";
import FormWrapper from "../../ui/form/FormWrapper";
import Modal, { ModalProps } from "../../ui/Modal";
import Tile, { TileGrid } from "../../ui/Tile";
import { snakeToTitle } from "../../utils";
import "./IntegrationModal.css";
import { ChevronLeftIcon } from "../../ui/icons";
import { useTranslation } from "react-i18next";

interface IntegrationFormParams {
  location: Location;
  meta: ProviderMeta;
  provider?: Provider;
  onChange: (provider: Provider) => void;
  isGlobal?: boolean;
}

export function IntegrationForm({
  location,
  provider: defaultProvider,
  onChange,
  meta,
  isGlobal,
}: IntegrationFormParams) {
  const { t } = useTranslation();
  const [provider, setProvider] = useState<Provider | undefined>(
    defaultProvider
  );
  const { type, group } = meta;
  useEffect(() => {
    if (defaultProvider) {
      const getProvider = isGlobal
        ? api.providers.getGlobal(
            defaultProvider.group,
            defaultProvider.type,
            defaultProvider.id
          )
        : api.providers.get(
            location.id,
            defaultProvider.group,
            defaultProvider.type,
            defaultProvider.id
          );

      getProvider
        .then((provider) => {
          setProvider(provider);
        })
        .catch(() => {});
    }
  }, [defaultProvider]);

  async function handleCreate({
    name,
    rate_limit,
    rate_interval,
    data = {},
  }: ProviderCreateParams | ProviderUpdateParams) {
    const params = { name, data, rate_limit, rate_interval, type, group };
    let value;

    if (isGlobal) {
      value = provider?.id
        ? await api.providers.updateGlobal(provider?.id, params)
        : await api.providers.createGlobal(params);
    } else {
      value = provider?.id
        ? await api.providers.update(location.id, provider?.id, params)
        : await api.providers.create(location.id, params);
    }

    onChange(value);
  }

  return (
    <FormWrapper<ProviderCreateParams>
      onSubmit={async (provider) => await handleCreate(provider)}
      submitLabel={provider?.id ? "Update Integration" : "Create Integration"}
      defaultValues={provider}
    >
      {(form) => (
        <>
          {provider?.id ? (
            <>
              {provider.setup.length > 0 && <h4>Details</h4>}
              {provider.setup?.map((item) => {
                return (
                  <TextInput
                    name={item.name}
                    key={item.name}
                    value={item.value}
                    disabled
                  />
                );
              })}
            </>
          ) : (
            <Alert title={meta.name} variant="plain">
              Fill out the fields below to setup this integration. For more
              information on this integration please see the documentation on
              our website
            </Alert>
          )}

          <h4>Config</h4>
          <TextInput.Field form={form} name="name" required />
          <SchemaFields
            parent="data"
            schema={meta.schema.properties.data}
            form={form}
          />
          <TextInput.Field
            form={form}
            type="number"
            name="rate_limit"
            subtitle="If you need to cap send rate, enter the maximum per interval limit."
          />
          <RadioInput.Field
            form={form}
            name="rate_interval"
            label={t("rate_interval")}
            options={[
              { key: "second", label: t("second") },
              { key: "minute", label: t("minute") },
              { key: "hour", label: t("hour") },
              { key: "day", label: t("day") },
            ]}
          />
        </>
      )}
    </FormWrapper>
  );
}

interface IntegrationModalProps extends Omit<ModalProps, "title"> {
  provider: Provider | undefined;
  onChange: (provider: Provider) => void;
  isGlobal?: boolean;
}

export default function IntegrationModal({
  onChange,
  provider,
  isGlobal,
  ...props
}: IntegrationModalProps) {
  const [location] = useContext(LocationContext);
  const [options] = useResolver(
    useCallback(
      async () =>
        isGlobal
          ? await api.providers.globalOptions()
          : await api.providers.options(location.id),
      [location, open, isGlobal]
    )
  );
  const [meta, setMeta] = useState<ProviderMeta | undefined>();

  useEffect(() => {
    setMeta(
      options?.find(
        (item) => item.group === provider?.group && item.type === provider?.type
      )
    );
  }, [provider]);

  const handleChange = (provider: Provider) => {
    onChange(provider);
    props.onClose(false);
    setMeta(undefined);
  };

  return (
    <Modal
      {...props}
      title={
        meta
          ? provider?.id
            ? `${provider?.name} (${meta.name})`
            : "Setup Integration"
          : isGlobal
          ? "Global Providers"
          : "Integrations"
      }
      size="regular"
    >
      {!meta ? (
        <>
          <p>
            {isGlobal
              ? "Configure global providers that will be available to all organizations."
              : "To get started, pick one of the integrations from the list below."}
          </p>
          <TileGrid>
            {options?.map((option) => (
              <Tile
                key={`${option.group}${option.type}`}
                title={option.name}
                onClick={() => setMeta(option)}
                iconUrl={option.icon}
              >
                {snakeToTitle(option.group)}
              </Tile>
            ))}
          </TileGrid>
        </>
      ) : (
        <>
          {!provider?.id && (
            <div style={{ marginBottom: "10px" }}>
              <Button
                icon={<ChevronLeftIcon />}
                variant="secondary"
                size="small"
                onClick={() => setMeta(undefined)}
              >
                {isGlobal ? "Global Providers" : "Integrations"}
              </Button>
            </div>
          )}
          <IntegrationForm
            location={location}
            provider={provider}
            meta={meta}
            onChange={handleChange}
            isGlobal={isGlobal}
          />
        </>
      )}
    </Modal>
  );
}
