import React, { useCallback, useState, useContext } from "react";
import api from "../../api";
import { LocationContext } from "../../contexts";
import { Product, SearchResult } from "../../types";
import PageContent from "../../ui/PageContent";
import Button from "../../ui/Button";
import { UploadIcon } from "../../ui/icons";
import { useTranslation } from "react-i18next";
import { SearchTable, useSearchTableQueryState } from "../../ui/SearchTable";
import Modal from "../../ui/Modal";
import FormWrapper from "../../ui/form/FormWrapper";
import UploadField from "../../ui/form/UploadField";
import UploadInstructions from "../../ui/form/UploadInstructions";

const productFields = [
  {
    name: "meta_sku",
    required: true,
    description: "Unique identifier for the product",
  },
  {
    name: "retailer_id",
    required: true,
    description: "Retailer's product identifier",
  },
  {
    name: "raw_product_name",
    required: true,
    description: "Original product name",
  },
  {
    name: "product_name",
    required: true,
    description: "Standardized product name",
  },
  {
    name: "medical",
    required: true,
    description: "true/false if product is medical",
  },
  {
    name: "recreational",
    required: true,
    description: "true/false if product is recreational",
  },
  { name: "cann_sku_id", description: "Cannabis SKU identifier" },
  { name: "brand_name", description: "Product brand name" },
  { name: "brand_id", description: "Brand identifier (number)" },
  { name: "url", description: "Product URL" },
  { name: "image_url", description: "Product image URL" },
  { name: "raw_weight_string", description: "Original weight string" },
  { name: "display_weight", description: "Formatted weight for display" },
  { name: "raw_product_category", description: "Original product category" },
  { name: "category", description: "Standardized product category" },
  { name: "raw_subcategory", description: "Original product subcategory" },
  { name: "subcategory", description: "Standardized product subcategory" },
  { name: "product_tags", description: "Comma-separated list of tags" },
  { name: "percentage_thc", description: "THC percentage (number)" },
  { name: "percentage_cbd", description: "CBD percentage (number)" },
  { name: "mg_thc", description: "THC content in milligrams (number)" },
  { name: "mg_cbd", description: "CBD content in milligrams (number)" },
  {
    name: "quantity_per_package",
    description: "Number of items per package (number)",
  },
  { name: "latest_price", description: "Current product price (number)" },
  { name: "menu_provider", description: "Menu provider identifier" },
];

export default function ProductsPage() {
  const { t } = useTranslation();
  const [location] = useContext(LocationContext);
  const [isUploadOpen, setIsUploadOpen] = useState(false);

  const state = useSearchTableQueryState<Product>(
    useCallback(
      async (params) => {
        return await api.products.search(location.id, {
          ...params,
          limit: 25,
          sort: params.sort || "created_at",
          direction: params.direction || "desc",
        });
      },
      [location.id]
    )
  );

  const uploadProducts = async (file: FileList) => {
    if (!file[0].name.toLowerCase().endsWith(".csv")) {
      alert(t("only_csv_files_allowed"));
      return;
    }

    const formData = new FormData();
    formData.append("productsData", file[0]);

    await api.products.upload(location.id, formData);
    setIsUploadOpen(false);
    await state.reload();
  };

  const downloadExample = () => {
    const headers = productFields.map((f) => f.name).join(",");
    const example =
      headers +
      "\n" +
      'SKU123,RET456,Raw Blue Dream,Blue Dream,true,false,CBD123,Top Brand,1001,https://example.com/product,https://example.com/image.jpg,"3.5g","3.5g",Raw Flower,Flower,Raw Indica,Indica,"premium,popular",24.5,0.5,245,5,1,29.99,provider1';

    const blob = new Blob([example], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "products_example.csv";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };

  return (
    <>
      <PageContent
        title={t("products")}
        actions={
          <Button icon={<UploadIcon />} onClick={() => setIsUploadOpen(true)}>
            {t("upload_products")}
          </Button>
        }
      >
        <SearchTable
          {...state}
          results={state.results as SearchResult<Product>}
          columns={[
            {
              key: "product_name",
              title: t("product_name"),
              sortable: true,
            },
            {
              key: "brand_name",
              title: t("brand_name"),
              sortable: true,
            },
            {
              key: "category",
              title: t("category"),
              sortable: true,
            },
            {
              key: "latest_price",
              title: t("price"),
              sortable: true,
            },
            {
              key: "medical",
              title: t("medical"),
              sortable: true,
              cell: ({ item }) => (item.medical ? "Yes" : "No"),
            },
            {
              key: "recreational",
              title: t("recreational"),
              sortable: true,
              cell: ({ item }) => (item.recreational ? "Yes" : "No"),
            },
          ]}
          enableSearch
          tagEntity="products"
        />
      </PageContent>

      <Modal
        title={t("upload_products")}
        open={isUploadOpen}
        onClose={() => setIsUploadOpen(false)}
      >
        <FormWrapper<{ file: FileList }>
          onSubmit={async (form) => await uploadProducts(form.file)}
          submitLabel={t("upload")}
        >
          {(form) => (
            <>
              <UploadInstructions
                fields={productFields}
                onDownloadExample={downloadExample}
                acceptedFormat=".csv"
              />
              <UploadField
                form={form}
                name="file"
                label={t("file")}
                accept=".csv"
                required
              />
            </>
          )}
        </FormWrapper>
      </Modal>
    </>
  );
}
