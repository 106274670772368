import React, { useCallback, useState, useContext } from "react";
import api from "../../api";
import { LocationContext } from "../../contexts";
import { POSData, SearchResult } from "../../types";
import PageContent from "../../ui/PageContent";
import Button from "../../ui/Button";
import { UploadIcon } from "../../ui/icons";
import { useTranslation } from "react-i18next";
import { SearchTable, useSearchTableQueryState } from "../../ui/SearchTable";
import Modal from "../../ui/Modal";
import FormWrapper from "../../ui/form/FormWrapper";
import UploadField from "../../ui/form/UploadField";

export default function POSDataPage() {
  const { t } = useTranslation();
  const [location] = useContext(LocationContext);
  const [isUploadOpen, setIsUploadOpen] = useState(false);

  const state = useSearchTableQueryState<POSData>(
    useCallback(
      async (params) => {
        return await api.pos.search(location.id, {
          ...params,
          limit: 25,
          sort: params.sort || "order_date",
          direction: params.direction || "desc",
        });
      },
      [location.id]
    )
  );

  const uploadPOSData = async (file: FileList) => {
    const formData = new FormData();
    formData.append("posData", file[0]);

    await api.pos.upload(location.id, formData);
    setIsUploadOpen(false);
    await state.reload();
  };

  return (
    <>
      <PageContent
        title={t("sales_data")}
        actions={
          <Button icon={<UploadIcon />} onClick={() => setIsUploadOpen(true)}>
            {t("upload_pos_data")}
          </Button>
        }
      >
        <SearchTable
          {...state}
          results={state.results as SearchResult<POSData>}
          columns={[
            {
              key: "location_name",
              title: t("location_name"),
              sortable: true,
            },
            {
              key: "product_name",
              title: t("product_name"),
              sortable: true,
            },
            {
              key: "master_category",
              title: t("master_category"),
              sortable: true,
            },
            {
              key: "order_date",
              title: t("order_date"),
              sortable: true,
              cell: ({ item }) => new Date(item.order_date).toLocaleString(),
            },
            {
              key: "customer_type",
              title: t("customer_type"),
              sortable: true,
            },
            {
              key: "budtender_name",
              title: t("budtender_name"),
              sortable: true,
            },
            {
              key: "gross_sales",
              title: t("gross_sales"),
              sortable: true,
              cell: ({ item }) => item.gross_sales,
            },
          ]}
          enableSearch
          tagEntity="pos_data"
        />
      </PageContent>

      <Modal
        title={t("upload_pos_data")}
        open={isUploadOpen}
        onClose={() => setIsUploadOpen(false)}
      >
        <FormWrapper<{ file: FileList }>
          onSubmit={async (form) => await uploadPOSData(form.file)}
          submitLabel={t("upload")}
        >
          {(form) => (
            <>
              <p>{t("upload_pos_data_instructions")}</p>
              <UploadField form={form} name="file" label={t("file")} required />
            </>
          )}
        </FormWrapper>
      </Modal>
    </>
  );
}
