import React, { useState } from "react";
import { Alert, Button, Modal } from "../ui";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import AutomationCreator from "./AutomationCreator";
import api from "../api";
import { Insight, InsightStatus } from "../types";

interface InsightCardProps {
  insight: Insight;
  onRefresh?: () => void;
}

export default function InsightCard({ insight, onRefresh }: InsightCardProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showAutomationCreator, setShowAutomationCreator] = useState(false);

  const getVariant = () => {
    if (insight.impact === "low") return "success";
    return insight.impact === "high" ? "error" : "warn";
  };

  const handleCreateAutomation = () => {
    setShowAutomationCreator(true);
  };

  const handleStatusUpdate = async (status: InsightStatus) => {
    await api.insights.updateStatus(insight.location_id, insight.id, status);
    onRefresh?.();
  };

  return (
    <>
      <Alert
        variant={getVariant()}
        title={insight.title}
        body={insight.description}
        actions={
          insight.type !== "general" && (
            <div className="flex gap-3" style={{ display: "flex", gap: "8px" }}>
              <Button
                onClick={handleCreateAutomation}
                variant="secondary"
                size="small"
              >
                {t("view_details")}
              </Button>
              <Button
                onClick={() => handleStatusUpdate("dismissed")}
                size="small"
              >
                {t("dismiss")}
              </Button>
            </div>
          )
        }
      />

      {showAutomationCreator && (
        <Modal
          open={showAutomationCreator}
          onClose={() => setShowAutomationCreator(false)}
          title={insight.title}
        >
          <AutomationCreator
            insight={insight}
            onClose={() => setShowAutomationCreator(false)}
          />
        </Modal>
      )}
    </>
  );
}
