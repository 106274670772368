import { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import api from "../../api";
import PageContent from "../../ui/PageContent";
import { SearchTable, useSearchTableQueryState } from "../../ui/SearchTable";
import { useRoute } from "../router";
import { useTranslation } from "react-i18next";
import Button from "../../ui/Button";
import { UploadIcon } from "../../ui/icons";
import Modal from "../../ui/Modal";
import FormWrapper from "../../ui/form/FormWrapper";
import UploadField from "../../ui/form/UploadField";
import UploadInstructions from "../../ui/form/UploadInstructions";

const userFields = [
  {
    name: "external_id",
    required: true,
    description: "Unique identifier for the user",
  },
  { name: "email", description: "User's email address" },
  {
    name: "phone",
    description: "User's phone number (with country code, e.g. +1234567890)",
  },
  { name: "timezone", description: "User's timezone (e.g. America/New_York)" },
  { name: "locale", description: "User's preferred language (e.g. en, es)" },
  { name: "first_name", description: "User's first name" },
  { name: "last_name", description: "User's last name" },
  {
    name: "created_at",
    description: "When the user was created (YYYY-MM-DD HH:mm:ss)",
  },
];

export default function Users() {
  const { locationId = "" } = useParams();
  const { t } = useTranslation();
  const route = useRoute();
  const [isUploadOpen, setIsUploadOpen] = useState(false);

  const state = useSearchTableQueryState(
    useCallback(
      async (params) => await api.users.search(locationId, params),
      [locationId]
    )
  );

  const uploadUsers = async (file: FileList) => {
    if (!file[0].name.toLowerCase().endsWith(".csv")) {
      alert(t("only_csv_files_allowed"));
      return;
    }

    const formData = new FormData();
    formData.append("users", file[0]);

    await api.users.upload(locationId, file[0]);
    setIsUploadOpen(false);
    await state.reload();
  };

  const downloadExample = () => {
    const headers = userFields.map((f) => f.name).join(",");
    const example =
      "external_id,email,phone,timezone,locale,first_name,last_name,created_at\n" +
      "USER123,john@example.com,+12345678900,America/New_York,en,John,Doe,2024-01-01 12:00:00";

    const blob = new Blob([headers + "\n" + example], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "users_example.csv";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };

  return (
    <>
      <PageContent
        title={t("customers")}
        actions={
          <Button icon={<UploadIcon />} onClick={() => setIsUploadOpen(true)}>
            {t("import_users")}
          </Button>
        }
      >
        <SearchTable
          {...state}
          columns={[
            { key: "full_name", title: t("name"), sortable: true },
            { key: "external_id", title: t("external_id") },
            { key: "email", title: t("email"), sortable: true },
            { key: "phone", title: t("phone") },
            { key: "locale", title: t("language") },
            { key: "created_at", title: t("created_at"), sortable: true },
          ]}
          onSelectRow={({ id }) => route(`users/${id}`)}
          enableSearch
          searchPlaceholder={t("search_users")}
          tagEntity="users"
        />
      </PageContent>

      <Modal
        title={t("import_users")}
        open={isUploadOpen}
        onClose={() => setIsUploadOpen(false)}
      >
        <FormWrapper<{ file: FileList }>
          onSubmit={async (form) => await uploadUsers(form.file)}
          submitLabel={t("upload")}
        >
          {(form) => (
            <>
              <UploadInstructions
                fields={userFields}
                onDownloadExample={downloadExample}
                acceptedFormat=".csv"
              />
              <UploadField
                form={form}
                name="file"
                label={t("file")}
                accept=".csv"
                required
              />
            </>
          )}
        </FormWrapper>
      </Modal>
    </>
  );
}
