import { useContext, useState, useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import PageContent from "../../ui/PageContent";
import { LocationContext } from "../../contexts";
import { PreferencesContext } from "../../ui/PreferencesContext";
import { Chart } from "react-charts";
import { TileGrid } from "../../ui/Tile";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  PieChart,
  Pie,
  Cell,
} from "recharts";
import Tile from "../../ui/Tile";
import { CampaignTag } from "../campaign/Campaigns";
import { InfoTable } from "../../ui/InfoTable";
import { Alert, Button, Modal, Columns, Column } from "../../ui";
import { SingleSelect } from "../../ui/form/SingleSelect";
import {
  DashboardIcon,
  UsersIcon,
  PosIcon,
  InsightsIcon,
  SegmentationIcon,
  CampaignsIcon,
  EmailIcon,
  MessageIcon,
  TrendingUpIcon,
  ChartBarIcon,
  TagIcon,
  GiftIcon,
} from "../../ui/icons";
import { formatDate } from "../../utils";
import { AxisOptions } from "react-charts";
import { useNavigate } from "react-router-dom";
import Heading from "../../ui/Heading";
import api from "../../api";
import { Campaign } from "../../types";
import { localStorageGetJson, localStorageSetJson } from "../../utils";
import "./Dashboard.css";
import { AIScoreCard } from "../../ui/AIScoreCard";
import { BudtenderPerformance } from "./BudtenderPerformance";
import { EmptyStateCard } from "../../ui/EmptyCard";
import InsightCard from "../../ui/InsightCard";

interface Metric {
  date: Date;
  value: number;
}

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

export default function Dashboard() {
  const [location] = useContext(LocationContext);
  const [preferences] = useContext(PreferencesContext);
  const { t } = useTranslation();
  const navigate = useNavigate();
  // Just keep track of the timeframe value
  const [timeframe, setTimeframe] = useState<String>(() => {
    return localStorageGetJson<String>("dashboard_timeframe") || "7d";
  });

  // Simplify the change handler to just work with string values
  const handleTimeframeChange = (value: String) => {
    setTimeframe(value);
    localStorageSetJson("dashboard_timeframe", value);
  };

  const [dashboardData, setDashboardData] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [showActionsModal, setShowActionsModal] = useState<boolean>(false);
  const [selectedInsight, setSelectedInsight] = useState<any>(null);

  useEffect(() => {
    const fetchDashboardData = async () => {
      setLoading(true);
      try {
        const data = await api.dashboard.get(location.id, { timeframe });
        setDashboardData(data);
      } catch (error) {
        console.error("Error fetching dashboard data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchDashboardData();
  }, [location.id, timeframe]);

  // Helper functions for data validation
  const formatCurrency = (value: number | null | undefined) => {
    if (value === null || value === undefined) return t("no_data");
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value);
  };

  const formatNumber = (
    value: number | null | undefined,
    decimals: number = 2
  ) => {
    if (value === null || value === undefined) return t("no_data");
    return new Intl.NumberFormat("en-US", {
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals,
    }).format(value);
  };

  const formatPercentage = (value: number | null | undefined) => {
    if (value === null || value === undefined) return t("no_data");
    return new Intl.NumberFormat("en-US", {
      style: "percent",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value / 100);
  };

  const hasChartData = (data: any[] | null | undefined) => {
    return Array.isArray(data) && data.length > 0;
  };

  // Safely prepare chart data
  const revenueData = useMemo(
    () => [
      {
        label: "Revenue",
        data:
          dashboardData?.charts?.revenue?.map((item: any) => ({
            date: new Date(item.order_date),
            value: item.revenue,
          })) || [],
      },
    ],
    [dashboardData?.charts?.revenue]
  );

  const salesData = useMemo(
    () => [
      {
        label: "Sales",
        data:
          dashboardData?.charts?.sales?.map((item: any) => ({
            date: new Date(item.order_date),
            value: item.total_orders,
          })) || [],
      },
    ],
    [dashboardData?.charts?.sales]
  );

  // Keep existing axis configurations
  const primaryAxis = useMemo(
    (): AxisOptions<Metric> => ({
      getValue: (datum: Metric) => datum.date,
    }),
    []
  );

  const secondaryAxes = useMemo(
    (): Array<AxisOptions<Metric>> => [
      {
        getValue: (datum: Metric) => datum.value,
        elementType: "line",
        tickCount: 5,
        shouldNice: true,
        formatters: {
          scale: (value: number) => formatCurrency(value),
        },
      },
    ],
    []
  );

  // Update timeframe options (remove 'custom')
  const timeframeOptions = ["7d", "30d", "90d", "1y", "all"];

  // Simplify the TimeframeSelector component
  const TimeframeSelector = (
    <div className="mb-6">
      <SingleSelect
        value={timeframe}
        onChange={handleTimeframeChange}
        getOptionDisplay={(value) => getTimeframeLabel(value)}
        options={timeframeOptions}
      />
    </div>
  );

  // Update getTimeframeLabel function (remove custom case)
  const getTimeframeLabel = (timeframe: String) => {
    switch (timeframe) {
      case "7d":
        return t("last_7_days");
      case "30d":
        return t("last_30_days");
      case "90d":
        return t("last_90_days");
      case "1y":
        return t("last_year");
      case "all":
        return t("all_time");
      default:
        return timeframe;
    }
  };

  if (loading) {
    return (
      <PageContent title={t("dashboard")} desc={t("dashboard_description")}>
        <div className="flex items-center justify-center h-64">
          <div className="text-gray-500">{t("loading")}</div>
        </div>
      </PageContent>
    );
  }

  // If there's no data at all for the location
  if (!dashboardData?.hasData) {
    return (
      <PageContent title={t("dashboard")}>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {!dashboardData?.hasPosData && (
            <div
              className={`bg-white dark:bg-gray-800 p-8 rounded-lg shadow-sm ${
                !dashboardData?.hasUserData ? "md:col-span-2" : ""
              }`}
            >
              <EmptyStateCard
                icon={PosIcon}
                title={t("no_pos_data")}
                description={t("no_pos_data_description")}
                buttonText={t("upload_pos_data")}
                onClick={() => {
                  navigate(`/locations/${location.id}/sales-data`, {
                    state: { showUploadModal: true },
                  });
                }}
              />
            </div>
          )}

          {!dashboardData?.hasUserData && (
            <div
              className={`bg-white dark:bg-gray-800 p-8 rounded-lg shadow-sm ${
                !dashboardData?.hasPosData ? "md:col-span-2" : ""
              }`}
            >
              <EmptyStateCard
                icon={UsersIcon}
                title={t("no_users")}
                description={t("no_users_description")}
                buttonText={t("import_users")}
                onClick={() => {
                  navigate(`/locations/${location.id}/users`, {
                    state: { showUploadModal: true },
                  });
                }}
              />
            </div>
          )}
        </div>
      </PageContent>
    );
  }

  return (
    <PageContent title={t("dashboard")} desc={t("dashboard_description")}>
      {TimeframeSelector}

      {/* Primary KPIs */}
      <div className="mb-10">
        <TileGrid numColumns={4}>
          <Tile
            title={formatCurrency(dashboardData?.kpi?.revenue)}
            icon={<PosIcon />}
            className="bg-white dark:bg-gray-800 shadow-sm"
          >
            {t("revenue")}
          </Tile>
          <Tile
            title={formatNumber(dashboardData?.kpi?.customers, 0)}
            icon={<UsersIcon />}
            className="bg-white dark:bg-gray-800 shadow-sm"
          >
            {t("customers")}
          </Tile>
          <Tile
            title={formatCurrency(dashboardData?.kpi?.averageOrder)}
            icon={<DashboardIcon />}
            className="bg-white dark:bg-gray-800 shadow-sm"
          >
            {t("average_order")}
          </Tile>
          <Tile
            title={formatCurrency(dashboardData?.kpi?.customerValue)}
            icon={<UsersIcon />}
            className="bg-white dark:bg-gray-800 shadow-sm"
          >
            {t("customer_value")}
          </Tile>
        </TileGrid>
      </div>

      {/* Campaign Performance */}
      <div className="mb-10">
        <Heading
          title={t("campaign_performance")}
          size="h4"
          className="mb-6 text-gray-900 dark:text-gray-100"
        />
        <TileGrid numColumns={4}>
          <Tile
            title={formatPercentage(dashboardData?.campaignMetrics?.openRate)}
            icon={<EmailIcon />}
            className="bg-white dark:bg-gray-800 shadow-sm"
          >
            {t("average_open_rate")}
          </Tile>
          <Tile
            title={formatPercentage(dashboardData?.campaignMetrics?.clickRate)}
            icon={<MessageIcon />}
            className="bg-white dark:bg-gray-800 shadow-sm"
          >
            {t("average_click_rate")}
          </Tile>
          <Tile
            title={formatPercentage(
              dashboardData?.campaignMetrics?.deliveryRate
            )}
            icon={<CampaignsIcon />}
            className="bg-white dark:bg-gray-800 shadow-sm"
          >
            {t("average_delivery_rate")}
          </Tile>
          <Tile
            title={formatNumber(
              dashboardData?.campaignMetrics?.totalCampaigns,
              0
            )}
            icon={<InsightsIcon />}
            className="bg-white dark:bg-gray-800 shadow-sm"
          >
            {t("total_campaigns")}
          </Tile>
        </TileGrid>
      </div>

      {/* AI Score */}
      {/* <div className="mb-10">
        <AIScoreCard
          score={dashboardData.aiScore.score}
          factors={dashboardData.aiScore.factors}
        />
      </div> */}

      {/* Charts Section - Revenue and Sales */}
      <div className="mb-10">
        <Columns>
          <Column>
            <div className="bg-white dark:bg-gray-800 p-8 rounded-lg shadow-sm">
              <Heading
                title={t("revenue_over_time")}
                size="h4"
                className="mb-6 text-gray-900 dark:text-gray-100"
              />
              {hasChartData(dashboardData?.charts?.revenue) ? (
                <div style={{ position: "relative", height: "300px" }}>
                  <Chart
                    options={{
                      data: revenueData,
                      primaryAxis,
                      secondaryAxes,
                      initialWidth: 500,
                      initialHeight: 300,
                      tooltip: true,
                      dark: preferences.mode === "dark",
                    }}
                  />
                </div>
              ) : (
                <EmptyStateCard
                  icon={InsightsIcon}
                  title={t("no_revenue_data")}
                  description={t("no_data_timeframe", {
                    timeframe: getTimeframeLabel(timeframe),
                  })}
                  buttonText={t("change_timeframe")}
                  onClick={() => {}} // User can use the timeframe selector above
                />
              )}
            </div>
          </Column>
          <Column>
            <div className="bg-white dark:bg-gray-800 p-8 rounded-lg shadow-sm">
              <Heading
                title={t("sales_over_time")}
                size="h4"
                className="mb-6 text-gray-900 dark:text-gray-100"
              />
              {hasChartData(dashboardData?.charts?.sales) ? (
                <div style={{ position: "relative", height: "300px" }}>
                  <Chart
                    options={{
                      data: salesData,
                      primaryAxis,
                      secondaryAxes,
                      initialWidth: 500,
                      initialHeight: 300,
                      tooltip: true,
                      dark: preferences.mode === "dark",
                    }}
                  />
                </div>
              ) : (
                <EmptyStateCard
                  icon={PosIcon}
                  title={t("no_sales_data")}
                  description={t("no_data_timeframe", {
                    timeframe: getTimeframeLabel(timeframe),
                  })}
                  buttonText={t("change_timeframe")}
                  onClick={() => {}} // User can use the timeframe selector above
                />
              )}
            </div>
          </Column>
        </Columns>
      </div>

      {/* Time Distribution and Category Performance */}
      <div className="mb-10">
        <Columns>
          <Column>
            <div className="bg-white dark:bg-gray-800 p-8 rounded-lg shadow-sm">
              <Heading
                title={t("category_performance")}
                size="h4"
                className="mb-6 text-gray-900 dark:text-gray-100"
              />
              {Array.isArray(dashboardData?.categoryPerformance) &&
              dashboardData.categoryPerformance.length > 0 ? (
                <BarChart
                  width={500}
                  height={300}
                  data={dashboardData.categoryPerformance.map(
                    (category: any) => ({
                      name: category.master_category || t("uncategorized"),
                      revenue: parseFloat(category.revenue),
                      sales: category.total_sales,
                    })
                  )}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    dataKey="name"
                    angle={-45}
                    textAnchor="end"
                    height={70}
                    interval={0}
                  />
                  <YAxis yAxisId="left" orientation="left" stroke="#8884d8" />
                  <YAxis yAxisId="right" orientation="right" stroke="#82ca9d" />
                  <Tooltip />
                  <Legend />
                  <Bar
                    yAxisId="left"
                    dataKey="revenue"
                    fill="#8884d8"
                    name={t("revenue")}
                  />
                  <Bar
                    yAxisId="right"
                    dataKey="sales"
                    fill="#82ca9d"
                    name={t("sales")}
                  />
                </BarChart>
              ) : (
                <EmptyStateCard
                  icon={PosIcon}
                  title={t("no_category_data")}
                  description={t("no_data_timeframe", {
                    timeframe: getTimeframeLabel(timeframe),
                  })}
                  buttonText={t("change_timeframe")}
                  onClick={() => {}}
                />
              )}
            </div>
          </Column>
          <Column>
            <div className="bg-white dark:bg-gray-800 p-8 rounded-lg shadow-sm">
              <Heading
                title={t("sales_by_time")}
                size="h4"
                className="mb-6 text-gray-900 dark:text-gray-100"
              />
              <BarChart
                width={500}
                height={300}
                data={[
                  {
                    time: t("morning"),
                    sales: dashboardData?.timeDistribution?.morning_sales || 0,
                  },
                  {
                    time: t("afternoon"),
                    sales:
                      dashboardData?.timeDistribution?.afternoon_sales || 0,
                  },
                  {
                    time: t("evening"),
                    sales: dashboardData?.timeDistribution?.evening_sales || 0,
                  },
                ]}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="time" />
                <YAxis />
                <Tooltip />
                <Bar dataKey="sales" fill="#3b82f6" />
              </BarChart>
            </div>
          </Column>
        </Columns>
      </div>

      {/* Customer Metrics */}
      <div className="mb-10">
        <TileGrid numColumns={4}>
          <Tile
            title={formatNumber(dashboardData?.customerMetrics?.total, 0)}
            icon={<UsersIcon />}
            className="bg-white dark:bg-gray-800 shadow-sm"
          >
            {t("total_customers_all_time")}
          </Tile>
          <Tile
            title={formatNumber(dashboardData?.customerMetrics?.new, 0)}
            icon={<UsersIcon />}
            className="bg-white dark:bg-gray-800 shadow-sm"
          >
            {t("new_customers_timeframe", {
              timeframe: getTimeframeLabel(timeframe),
            })}
          </Tile>
          <Tile
            title={formatNumber(
              dashboardData?.customerMetrics?.purchasedInTimeframe,
              0
            )}
            icon={<UsersIcon />}
            className="bg-white dark:bg-gray-800 shadow-sm"
          >
            {t("customers_purchased_timeframe", {
              timeframe: getTimeframeLabel(timeframe),
            })}
          </Tile>
          <Tile
            title={formatNumber(dashboardData?.customerMetrics?.returning, 0)}
            icon={<UsersIcon />}
            className="bg-white dark:bg-gray-800 shadow-sm"
          >
            {t("returning_customers_timeframe", {
              timeframe: getTimeframeLabel(timeframe),
            })}
          </Tile>
        </TileGrid>
      </div>

      {/* Best Selling Products and Active Campaigns */}
      <div className="mb-10">
        <Columns>
          <Column>
            <div className="bg-white dark:bg-gray-800 p-8 rounded-lg shadow-sm">
              <Heading
                title={t("best_selling_products")}
                size="h4"
                className="mb-6 text-gray-900 dark:text-gray-100"
              />
              {Array.isArray(dashboardData?.kpi?.products) &&
              dashboardData.kpi.products.length > 0 ? (
                <InfoTable
                  rows={dashboardData.kpi.products.reduce(
                    (acc: any, product: any) => ({
                      ...acc,
                      [product.name]: (
                        <div className="flex items-center justify-between text-gray-700 dark:text-gray-300">
                          <span style={{ marginRight: "15px" }}>
                            {formatNumber(product.sales, 0)} {t("items_sold")}
                          </span>
                          <span className="font-medium">
                            {formatCurrency(product.revenue)}
                          </span>
                        </div>
                      ),
                    }),
                    {}
                  )}
                />
              ) : (
                <EmptyStateCard
                  icon={PosIcon}
                  title={t("no_product_data")}
                  description={t("no_data_timeframe", {
                    timeframe: getTimeframeLabel(timeframe),
                  })}
                  buttonText={t("change_timeframe")}
                  onClick={() => {}}
                />
              )}
            </div>
          </Column>
          <Column>
            <div className="bg-white dark:bg-gray-800 p-8 rounded-lg shadow-sm">
              <Heading
                title={t("active_campaigns")}
                size="h4"
                className="mb-6 text-gray-900 dark:text-gray-100"
              />
              {Array.isArray(dashboardData?.activeCampaigns) &&
              dashboardData.activeCampaigns.length > 0 ? (
                <InfoTable
                  rows={dashboardData.activeCampaigns.reduce(
                    (acc: any, campaign: any) => ({
                      ...acc,
                      [campaign.name]: (
                        <div className="flex items-center justify-between text-gray-700 dark:text-gray-300">
                          <span style={{ marginRight: "15px" }}>
                            {formatDate(preferences, campaign.created_at)}
                          </span>
                          <CampaignTag
                            state={campaign.state}
                            type={campaign.type}
                          />
                        </div>
                      ),
                    }),
                    {}
                  )}
                />
              ) : (
                <EmptyStateCard
                  icon={CampaignsIcon}
                  title={t("no_campaigns")}
                  description={t("no_campaigns_description")}
                  buttonText={t("create_campaign")}
                  onClick={() =>
                    navigate("/locations/" + location.id + "/campaigns", {
                      state: { showCreateModal: true },
                    })
                  }
                />
              )}
            </div>
          </Column>
        </Columns>
      </div>

      {/* Smokey's Insights */}
      <div className="mb-10">
        <div className="bg-white dark:bg-gray-800 p-8 rounded-lg shadow-sm">
          <Heading
            title={t("smokeys_insights")}
            size="h4"
            className="mb-6 text-gray-900 dark:text-gray-100"
          />
          {Array.isArray(dashboardData?.insights) &&
          dashboardData.insights.length > 0 ? (
            <div className="space-y-4">
              {dashboardData.insights.map((insight: any) => (
                <InsightCard
                  key={insight.id}
                  insight={insight}
                  onRefresh={() => {
                    // Refresh dashboard data when insight is updated
                    const fetchDashboardData = async () => {
                      setLoading(true);
                      try {
                        const data = await api.dashboard.get(location.id, {
                          timeframe,
                        });
                        setDashboardData(data);
                      } catch (error) {
                        console.error("Error fetching dashboard data:", error);
                      } finally {
                        setLoading(false);
                      }
                    };
                    fetchDashboardData();
                  }}
                />
              ))}
            </div>
          ) : (
            <EmptyStateCard
              icon={InsightsIcon}
              title={t("no_insights")}
              description={t("no_insights_description")}
              buttonText={t("generate_insights")}
              onClick={async () => {
                try {
                  setLoading(true);
                  const insights = await api.dashboard.generateInsights(
                    location.id
                  );
                  setDashboardData({
                    ...dashboardData,
                    insights,
                  });
                } catch (error) {
                  console.error("Error generating insights:", error);
                } finally {
                  setLoading(false);
                }
              }}
            />
          )}
        </div>
      </div>

      {/* Analytics Sections */}
      <div className="mb-10">
        <Columns>
          {/* Age Distribution */}
          <Column>
            <div className="bg-white dark:bg-gray-800 p-8 rounded-lg shadow-sm">
              <Heading
                title={t("age_distribution")}
                size="h4"
                className="mb-6 text-gray-900 dark:text-gray-100"
              />
              {dashboardData?.analytics?.ageDistribution ? (
                <BarChart
                  width={1000}
                  height={300}
                  data={[
                    {
                      age: t("under_30"),
                      count: dashboardData.analytics.ageDistribution.under_30,
                    },
                    {
                      age: t("30_to_45"),
                      count: dashboardData.analytics.ageDistribution.age_30_45,
                    },
                    {
                      age: t("46_to_60"),
                      count: dashboardData.analytics.ageDistribution.age_46_60,
                    },
                    {
                      age: t("over_60"),
                      count: dashboardData.analytics.ageDistribution.over_60,
                    },
                  ]}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="age" />
                  <YAxis />
                  <Tooltip />
                  <Bar dataKey="count" fill="#4f46e5" />
                </BarChart>
              ) : (
                <EmptyStateCard
                  icon={UsersIcon}
                  title={t("no_age_data")}
                  description={t("no_age_data_description")}
                  buttonText={t("change_timeframe")}
                  onClick={() => {}}
                />
              )}
            </div>
          </Column>

          {/* Customer Type Analysis */}
          <Column>
            <div className="bg-white dark:bg-gray-800 p-8 rounded-lg shadow-sm">
              <Heading
                title={t("customer_type_analysis")}
                size="h4"
                className="mb-6 text-gray-900 dark:text-gray-100"
              />
              {dashboardData?.analytics?.customerTypeAnalysis &&
              dashboardData.analytics.customerTypeAnalysis.length > 0 ? (
                <BarChart
                  width={400}
                  height={300}
                  data={dashboardData.analytics.customerTypeAnalysis.map(
                    (item: any) => ({
                      customer_type:
                        item.type === "new"
                          ? t("new_customers")
                          : t("returning_customers"),
                      revenue: item.revenue,
                      total_orders: item.total_orders,
                    })
                  )}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="customer_type" />
                  <YAxis yAxisId="left" orientation="left" stroke="#8884d8" />
                  <YAxis yAxisId="right" orientation="right" stroke="#82ca9d" />
                  <Tooltip />
                  <Legend />
                  <Bar
                    yAxisId="left"
                    dataKey="revenue"
                    name={t("revenue")}
                    fill="#8884d8"
                  />
                  <Bar
                    yAxisId="right"
                    dataKey="total_orders"
                    name={t("orders")}
                    fill="#82ca9d"
                  />
                </BarChart>
              ) : (
                <EmptyStateCard
                  icon={UsersIcon}
                  title={t("no_customer_type_data")}
                  description={t("no_customer_type_data_description")}
                  buttonText={t("change_timeframe")}
                  onClick={() => {}}
                />
              )}
            </div>
          </Column>
        </Columns>
      </div>

      {/* Budtender Performance */}
      <div className="mb-10">
        <BudtenderPerformance
          data={dashboardData?.analytics?.budtenderPerformance || null}
        />
      </div>

      {/* Profitability Analysis */}
      <div className="mb-10">
        <div className="bg-white dark:bg-gray-800 p-8 rounded-lg shadow-sm">
          <Heading
            title={t("profitability_analysis")}
            size="h4"
            className="mb-6 text-gray-900 dark:text-gray-100"
          />
          <TileGrid numColumns={4}>
            <Tile
              title={formatCurrency(
                dashboardData?.analytics?.profitabilityAnalysis?.total_profit
              )}
              icon={<TrendingUpIcon />}
              className="bg-white dark:bg-gray-800 shadow-sm"
            >
              {t("total_profit")}
            </Tile>
            <Tile
              title={formatPercentage(
                dashboardData?.analytics?.profitabilityAnalysis?.profit_margin
              )}
              icon={<ChartBarIcon />}
              className="bg-white dark:bg-gray-800 shadow-sm"
            >
              {t("profit_margin")}
            </Tile>
            <Tile
              title={formatCurrency(
                dashboardData?.analytics?.profitabilityAnalysis?.total_discounts
              )}
              icon={<TagIcon />}
              className="bg-white dark:bg-gray-800 shadow-sm"
            >
              {t("total_discounts")}
            </Tile>
            <Tile
              title={formatCurrency(
                dashboardData?.analytics?.profitabilityAnalysis
                  ?.total_loyalty_discounts
              )}
              icon={<GiftIcon />}
              className="bg-white dark:bg-gray-800 shadow-sm"
            >
              {t("loyalty_discounts")}
            </Tile>
          </TileGrid>
        </div>
      </div>

      {/* Actions Modal */}
      {showActionsModal && selectedInsight && (
        <Modal
          title={selectedInsight.title}
          open={showActionsModal}
          onClose={() => {
            setShowActionsModal(false);
            setSelectedInsight(null);
          }}
        >
          <div className="p-6">
            <p className="text-gray-600 dark:text-gray-300 mb-4">
              {selectedInsight.description}
            </p>
            <h4 className="font-medium mb-2">{t("recommended_actions")}:</h4>
            <ul className="space-y-2">
              {selectedInsight.actions.map((action: string, i: number) => (
                <li key={i} className="flex items-start gap-2">
                  <span>{action}</span>
                </li>
              ))}
            </ul>
            <div className="mt-6 flex justify-end">
              <Button
                onClick={() =>
                  navigate(
                    `/locations/${location.id}/${selectedInsight.type}`,
                    {
                      state: {
                        showCreateModal: true,
                        insight: selectedInsight,
                      },
                    }
                  )
                }
              >
                {t(`create_${selectedInsight.type}`)}
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </PageContent>
  );
}
