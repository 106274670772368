import { useContext, useState } from "react";
import { motion } from "framer-motion";
import Spinner from "./Spinner";
import api from "../api";
import { useTranslation } from "react-i18next";
import { LocationContext } from "../contexts";
import {
  CheckIcon,
  ClockIcon,
  ExclamationIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "./icons";
import {
  HiPencil,
  HiArrowPath,
  HiArrowLeft,
  HiInformationCircle,
} from "react-icons/hi2";
import { createUuid } from "../utils";
import Button from "./Button";
import { SingleSelect } from "./form/SingleSelect";
import { ChannelType, Insight } from "../types";
import clsx from "clsx";
import { Tooltip } from "./Tooltip";
import Dialog from "./Dialog";

type ButtonVariant = "primary" | "secondary" | "ghost";

const MODEL_OPTIONS = [
  { value: "gpt-4o", label: "GPT-4o" },
  { value: "gpt-4o-mini", label: "GPT-4o Mini" },
];

export interface AutomationCreatorProps {
  insight: Insight;
  onClose?: () => void;
}

interface AutomationItem {
  type: string;
  config: {
    [key: string]: any;
  };
  error?: string;
}

interface AutomationPlan {
  items: Record<string, AutomationItem>;
}

interface StepResult {
  item: AutomationItem;
  success: boolean;
  error?: string;
}

interface ResourceData {
  id: number;
  type: string;
  [key: string]: any;
}

interface FeedbackPayload {
  success: boolean;
  error?: string;
  steps?: any[];
  stepResults?: StepResult[];
}

type ImageQuality = "SD" | "HD" | "Advanced";

interface ImageQualityOption {
  value: ImageQuality;
  label: string;
  description: string;
}

const IMAGE_QUALITY_OPTIONS: ImageQualityOption[] = [
  {
    value: "SD",
    label: "Standard",
    description: "DALL-E generated image with separate text (Fastest)",
  },
  {
    value: "HD",
    label: "High Definition",
    description:
      "Ideogram generated image with embedded and separate text (Better quality)",
  },
  {
    value: "Advanced",
    label: "Advanced",
    description:
      "Combined Ideogram with custom text overlay (Best quality, slower)",
  },
];

interface StepActionsProps {
  onRegenerate: (
    stepIndex: number,
    prompt?: string,
    imageQuality?: ImageQuality,
    model?: string
  ) => Promise<void>;
  isCompleted: boolean;
  item: AutomationItem;
  itemKey: string;
  onRetry: (key: string) => Promise<void>;
  onShowDetails: () => void;
}

export default function AutomationCreator({
  insight,
  onClose,
}: AutomationCreatorProps) {
  const { t } = useTranslation();
  const [location] = useContext(LocationContext);
  const [currentStep, setCurrentStep] = useState<number | null>(null);
  const [completedSteps, setCompletedSteps] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [plan, setPlan] = useState<AutomationPlan | null | undefined>(
    insight.plan
  );
  const [isExecuting, setIsExecuting] = useState(false);
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState<string | null>(null);
  const [stepErrors, setStepErrors] = useState<Map<number, string>>(new Map());
  const [selectedModel, setSelectedModel] = useState("gpt-4o");
  const [createdResourceIds, setCreatedResourceIds] = useState<
    Map<string, { id: number; type: string; [key: string]: any }>
  >(new Map());
  const [selectedStep, setSelectedStep] = useState<{
    key: string;
    item: AutomationItem;
  } | null>(null);
  const [imageQuality, setImageQuality] = useState<ImageQuality>("SD");
  const [steps, setSteps] = useState<any[]>([]);
  const [showJson, setShowJson] = useState(false);

  const handleGenerate = async () => {
    const maxRetries = 3;
    let attempt = 0;
    let lastError: Error | null = null;

    while (attempt < maxRetries) {
      try {
        setIsLoading(true);
        console.log(
          `Generating automation plan with model: ${selectedModel} (attempt ${
            attempt + 1
          }/${maxRetries})`
        );
        const plan = await api.insights.getAutomationPlan(
          insight.location_id,
          insight.id,
          selectedModel,
          imageQuality
        );
        setPlan(plan);
        setIsLoading(false);
        return; // Success - exit the retry loop
      } catch (error) {
        console.error(
          `Error generating automation plan (attempt ${attempt + 1}):`,
          error
        );
        lastError =
          error instanceof Error ? error : new Error("Unknown error occurred");
        attempt++;

        // If this is not the last attempt, add a small delay before retrying
        if (attempt < maxRetries) {
          await new Promise((resolve) => setTimeout(resolve, 1000));
        }
      }
    }

    // If we get here, all retries failed
    console.error("All attempts to generate plan failed:", lastError);
    setIsLoading(false);
    setError(
      lastError?.message ||
        "Failed to generate automation plan after multiple attempts"
    );
  };

  const executeSteps = async () => {
    setError(null);
    setIsExecuting(true);
    const stepResults: Array<StepResult> = [];
    const newResourceIds = new Map<string, ResourceData>();

    try {
      if (!plan) {
        throw new Error("No automation plan available");
      }

      // Define the order of step types
      const stepOrder = ["tag", "list", "campaign", "template", "journey"];
      const items = plan.items;

      // Sort items based on the defined ordera
      const sortedItems = Object.entries(items).sort(([, a], [, b]) => {
        return (
          stepOrder.indexOf((a as AutomationItem).type) -
          stepOrder.indexOf((b as AutomationItem).type)
        );
      });

      // Execute items in order
      for (let i = 0; i < sortedItems.length; i++) {
        const [itemKey, item] = sortedItems[i] as [string, AutomationItem];
        setCurrentStep(i);
        try {
          console.log("Starting item", itemKey, "type:", item.type);
          console.log(
            "Current resource IDs:",
            Array.from(newResourceIds.entries())
          );

          const resourceData = await executeItem(item, itemKey, newResourceIds);

          if (!resourceData || !resourceData.id) {
            throw new Error(
              `Resource not created for item ${itemKey} (${item.type})`
            );
          }

          // Update our local map
          newResourceIds.set(itemKey, resourceData);

          // Update React state
          setCreatedResourceIds(new Map(newResourceIds));

          console.log("Created resource for item", itemKey, ":", resourceData);

          stepResults.push({
            item,
            success: true,
          });

          setCompletedSteps((prev) => [...prev, i]);
          setProgress(((i + 1) / sortedItems.length) * 100);

          // Add a small delay between steps
          await new Promise((resolve) => setTimeout(resolve, 100));
        } catch (error: any) {
          console.error(
            `Error executing item ${itemKey} (${item.type}):`,
            error
          );
          stepResults.push({
            item,
            success: false,
            error: error.message,
          });
          throw new Error(
            `Failed to execute ${item.type} item: ${error.message}`
          );
        }
      }

      // Send success feedback
      await api.insights.sendFeedback(location.id, insight.id, {
        success: true,
        stepResults,
      } as FeedbackPayload);
    } catch (error: any) {
      console.error("Error executing items:", error);
      setError(error.message || "Failed to execute automation plan");
      setCompletedSteps([]);
      setProgress(0);

      // Send error feedback
      await api.insights.sendFeedback(location.id, insight.id, {
        success: false,
        error: error.message,
        stepResults,
      } as FeedbackPayload);
    } finally {
      setCurrentStep(null);
      setIsExecuting(false);
    }
  };

  const replaceUuids = (rule: any) => {
    if (!rule) return null;
    const newUuid = createUuid();
    rule.uuid = newUuid;
    rule.root_uuid = newUuid;
    if (rule.children) {
      rule.children = rule.children.map((child: any) => {
        child.root_uuid = newUuid;
        child.parent_uuid = newUuid;
        child.uuid = createUuid();
        return child;
      });
    }
    return rule;
  };

  const executeItem = async (
    item: AutomationItem,
    itemKey: string,
    resourceIds: Map<string, ResourceData>
  ) => {
    console.log("Executing item:", item.type, "key:", itemKey);
    console.log("Current resourceIds:", Array.from(resourceIds.entries()));

    try {
      // Pre-execution validation
      if (!item.type || !item.config || !item.config[item.type]) {
        throw new Error(`Invalid item configuration for key ${itemKey}`);
      }

      switch (item.type) {
        case "tag": {
          if (!item.config.tag?.name) {
            throw new Error("Tag name is required");
          }
          const tagConfig = {
            name: item.config.tag.name,
            description: item.config.tag.description,
          };
          const tag = await api.tags.create(location.id, tagConfig);
          if (!tag?.id) {
            throw new Error("Failed to create tag - no ID returned");
          }
          const resourceData = {
            id: tag.id,
            type: "tag",
            tag: tag,
          };
          item.config.tag.id = tag.id;
          return resourceData;
        }

        case "list": {
          if (!item.config.list?.name) {
            throw new Error("List name is required");
          }
          const listConfig = {
            name: item.config.list.name,
            type: item.config.list.type || "dynamic",
            rule:
              item.config.list.type === "dynamic"
                ? replaceUuids(item.config.list.rule)
                : undefined,
            is_visible: item.config.list.is_visible ?? true,
            tags: Array.isArray(item.config.list.tags)
              ? item.config.list.tags
              : [],
          };
          const list = await api.lists.create(location.id, listConfig);
          if (!list?.id) {
            throw new Error("Failed to create list - no ID returned");
          }
          const resourceData = {
            id: list.id,
            type: "list",
            list: list,
          };
          item.config.list.id = list.id;
          return resourceData;
        }

        case "campaign": {
          if (!item.config.campaign?.name || !item.config.campaign?.channel) {
            throw new Error("Campaign name and channel are required");
          }

          // Handle both single list_ref and array of list_refs
          const listRefs: string[] = [];
          if (item.config.campaign.list_ref) {
            listRefs.push(item.config.campaign.list_ref);
          }
          if (Array.isArray(item.config.campaign.list_refs)) {
            listRefs.push(...item.config.campaign.list_refs);
          }

          // Also check numeric list_ids for backward compatibility
          const configListIds = item.config.campaign.list_ids || [];

          // Resolve list references and IDs
          const campaignListIds = await Promise.all([
            // Resolve string references
            ...listRefs.map(async (ref: string) => {
              const resolvedId = resourceIds.get(ref)?.id;
              if (!resolvedId) {
                throw new Error(`Failed to resolve list reference: ${ref}`);
              }
              return resolvedId;
            }),
            // Handle direct numeric IDs
            ...configListIds.map((id: number) => id),
          ]);

          // Handle exclusion list references
          const exclusionListRefs = Array.isArray(
            item.config.campaign.exclusion_list_refs
          )
            ? item.config.campaign.exclusion_list_refs
            : [];

          const exclusionListIds = await Promise.all([
            // Resolve exclusion list references
            ...exclusionListRefs.map(async (ref: string) => {
              const resolvedId = resourceIds.get(ref)?.id;
              if (!resolvedId) {
                throw new Error(
                  `Failed to resolve exclusion list reference: ${ref}`
                );
              }
              return resolvedId;
            }),
            // Include any direct exclusion list IDs
            ...(Array.isArray(item.config.campaign.exclusion_list_ids)
              ? item.config.campaign.exclusion_list_ids
              : []),
          ]);

          // If no lists specified, try to find matching list by naming convention
          if (campaignListIds.length === 0) {
            const campaignPrefix = itemKey.replace(/Campaign$/, "");
            const listKey = `${campaignPrefix}List`;
            const listResource = resourceIds.get(listKey);

            if (listResource && listResource.type === "list") {
              campaignListIds.push(listResource.id);
            }
          }

          if (campaignListIds.length === 0) {
            throw new Error(
              "Campaign must have at least one list (specify using list_ref, list_refs, or list_ids)"
            );
          }

          // Validate and format send_at
          let formattedSendAt = null;
          if (
            item.config.campaign.send_at &&
            item.config.campaign.send_at.trim() !== ""
          ) {
            try {
              const date = new Date(item.config.campaign.send_at);
              if (isNaN(date.getTime())) {
                throw new Error(
                  `Invalid send_at date: ${item.config.campaign.send_at}`
                );
              }
              formattedSendAt = date.toISOString();
            } catch (err) {
              const error = err as Error;
              throw new Error(`Invalid send_at format: ${error.message}`);
            }
          }

          // Validate subscription and provider
          if (
            !item.config.campaign.subscription_id ||
            !item.config.campaign.provider_id
          ) {
            throw new Error(
              "Campaign subscription_id and provider_id are required"
            );
          }

          const campaignConfig = {
            name: item.config.campaign.name,
            type: item.config.campaign.type || "blast",
            channel: item.config.campaign.channel as ChannelType,
            subscription_id: Number(item.config.campaign.subscription_id),
            provider_id: Number(item.config.campaign.provider_id),
            list_ids: campaignListIds,
            exclusion_list_ids: exclusionListIds.filter((id) => id !== null),
            tags: Array.isArray(item.config.campaign.tags)
              ? item.config.campaign.tags
              : [],
            send_in_user_timezone:
              item.config.campaign.send_in_user_timezone === true,
            send_at: formattedSendAt || undefined,
          };

          // Validate final config
          if (
            typeof campaignConfig.subscription_id !== "number" ||
            isNaN(campaignConfig.subscription_id)
          ) {
            throw new Error("Invalid subscription_id");
          }
          if (
            typeof campaignConfig.provider_id !== "number" ||
            isNaN(campaignConfig.provider_id)
          ) {
            throw new Error("Invalid provider_id");
          }
          if (!["email", "text"].includes(campaignConfig.channel)) {
            throw new Error("Invalid channel type");
          }

          console.log("Creating campaign with config:", campaignConfig);
          const campaign = await api.campaigns.create(
            location.id,
            campaignConfig
          );

          if (!campaign?.id) {
            throw new Error("Failed to create campaign - no ID returned");
          }
          const resourceData = {
            id: campaign.id,
            type: "campaign",
            campaign: campaign,
          };
          item.config.campaign.id = campaign.id;
          return resourceData;
        }

        case "template": {
          if (!item.config.template?.type || !item.config.template?.data) {
            throw new Error("Template type and data are required");
          }

          // Validate and resolve campaign reference
          let campaignId: number;
          if (item.config.template.campaign_ref) {
            // For new campaigns, look up by key in resourceIds
            const referencedCampaign = resourceIds.get(
              item.config.template.campaign_ref
            );
            if (!referencedCampaign || referencedCampaign.type !== "campaign") {
              throw new Error(
                `Failed to resolve campaign reference: ${item.config.template.campaign_ref}`
              );
            }
            campaignId = referencedCampaign.id;
          } else if (item.config.template.campaign_id) {
            // For existing campaigns, use the ID directly
            campaignId = item.config.template.campaign_id;
          } else {
            throw new Error(
              "Template must have either campaign_ref or campaign_id"
            );
          }

          // Validate required template data fields
          const requiredFields = [
            "editor",
            "name",
            "subject",
            "html",
            "text",
            "from",
          ];
          for (const field of requiredFields) {
            if (!item.config.template.data[field]) {
              throw new Error(`Template data missing required field: ${field}`);
            }
          }

          if (
            !item.config.template.data.from?.name ||
            !item.config.template.data.from?.email
          ) {
            throw new Error("Template from field must have name and email");
          }

          const templateConfig = {
            type: item.config.template.type,
            locale: item.config.template.locale || "en",
            data: item.config.template.data,
            campaign_id: campaignId,
          };

          const template = await api.templates.create(
            location.id,
            templateConfig
          );
          if (!template?.id) {
            throw new Error("Failed to create template - no ID returned");
          }
          const resourceData = {
            id: template.id,
            type: "template",
            template: template,
          };
          item.config.template.id = template.id;
          return resourceData;
        }

        default:
          throw new Error(`Unsupported item type: ${item.type}`);
      }
    } catch (error) {
      console.error(`Error executing ${item.type} item:`, error);
      throw error;
    }
  };

  const getStepStatus = (
    index: number
  ): "completed" | "in-progress" | "pending" | "queued" | "error" => {
    if (completedSteps.includes(index)) return "completed";
    if (currentStep === index) return "in-progress";
    if (currentStep === null && !isExecuting) return "pending";
    if (stepErrors.has(index)) return "error";
    return "queued";
  };

  const getStepIcon = (status: string) => {
    switch (status) {
      case "completed":
        return <CheckIcon />;
      case "in-progress":
        return <Spinner size="small" className="text-blue-500" />;
      case "queued":
        return <ClockIcon />;
      case "error":
        return <ExclamationIcon />;
      default:
        return (
          <div className="w-5 h-5 rounded-full border-2 border-gray-300 dark:border-gray-600" />
        );
    }
  };

  const getInsightTypeClass = (type: string) => {
    if (type === "general") {
      return "bg-green-50 dark:bg-green-900/20 text-green-700 dark:text-green-300";
    }
    return "";
  };

  const IconWrapper = ({
    children,
    className,
  }: {
    children: React.ReactNode;
    className?: string;
  }) => (
    <span
      className={clsx("w-4 h-4 flex items-center justify-center", className)}
    >
      {children}
    </span>
  );

  const ImageQualityToggle = ({
    value,
    onChange,
  }: {
    value: ImageQuality;
    onChange: (value: ImageQuality) => void;
  }) => {
    const { t } = useTranslation();

    return (
      <div className="flex items-center gap-4">
        <div className="flex items-center gap-2">
          <span className="text-sm font-medium text-gray-700 dark:text-gray-300">
            {t("image_quality")}
          </span>
          <Tooltip
            content={
              <div className="space-y-2 max-w-xs">
                {IMAGE_QUALITY_OPTIONS.map((option) => (
                  <div key={option.value}>
                    <div className="font-medium">{option.label}</div>
                    <div className="text-sm text-gray-200">
                      {option.description}
                    </div>
                  </div>
                ))}
              </div>
            }
          >
            <HiInformationCircle className="w-4 h-4 text-gray-400" />
          </Tooltip>
        </div>
        <div className="flex items-center bg-gray-100 dark:bg-gray-800 rounded-lg p-1">
          {IMAGE_QUALITY_OPTIONS.map((option) => (
            <button
              key={option.value}
              className={clsx(
                "px-3 py-1.5 text-sm font-medium rounded-md transition-colors",
                value === option.value
                  ? "bg-white dark:bg-gray-700 text-gray-900 dark:text-white shadow-sm"
                  : "text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300"
              )}
              onClick={() => onChange(option.value)}
            >
              {option.label}
            </button>
          ))}
        </div>
      </div>
    );
  };

  const StepDetails = ({
    item,
    itemKey,
    onClose,
    onRetry,
    onRegenerate,
  }: {
    item: AutomationItem;
    itemKey: string;
    onClose: () => void;
    onRetry: (key: string) => Promise<void>;
    onRegenerate: (key: string, prompt?: string) => Promise<void>;
  }) => {
    const { t } = useTranslation();
    const [showPromptDialog, setShowPromptDialog] = useState(false);
    const [showJson, setShowJson] = useState(false);
    const [prompt, setPrompt] = useState("");
    const [imageQuality, setImageQuality] = useState<ImageQuality>("SD");

    const renderFormattedDetails = () => {
      switch (item.type) {
        case "list":
          return (
            <div className="space-y-4">
              <div>
                <h3 className="text-sm font-medium text-gray-500 dark:text-gray-400">
                  {t("list_type")}
                </h3>
                <p className="mt-1 text-base text-gray-900 dark:text-gray-100">
                  {item.config.list.type === "dynamic"
                    ? t("dynamic_list")
                    : t("static_list")}
                </p>
              </div>
              {item.config.list.description && (
                <div>
                  <h3 className="text-sm font-medium text-gray-500 dark:text-gray-400">
                    {t("description")}
                  </h3>
                  <p className="mt-1 text-base text-gray-900 dark:text-gray-100">
                    {item.config.list.description}
                  </p>
                </div>
              )}
              {item.config.list.rule && (
                <div>
                  <h3 className="text-sm font-medium text-gray-500 dark:text-gray-400">
                    {t("filter_rules")}
                  </h3>
                  <div className="mt-1 bg-gray-50 dark:bg-gray-800 rounded-lg p-4">
                    <pre className="text-sm text-gray-600 dark:text-gray-400 whitespace-pre-wrap">
                      {JSON.stringify(item.config.list.rule, null, 2)}
                    </pre>
                  </div>
                </div>
              )}
            </div>
          );

        case "campaign":
          return (
            <div className="space-y-4">
              <div>
                <h3 className="text-sm font-medium text-gray-500 dark:text-gray-400">
                  {t("campaign_type")}
                </h3>
                <p className="mt-1 text-base text-gray-900 dark:text-gray-100">
                  {item.config.campaign.type}
                </p>
              </div>
              <div>
                <h3 className="text-sm font-medium text-gray-500 dark:text-gray-400">
                  {t("channel")}
                </h3>
                <p className="mt-1 text-base text-gray-900 dark:text-gray-100">
                  {item.config.campaign.channel}
                </p>
              </div>
              {item.config.campaign.send_in_user_timezone && (
                <div className="flex items-center gap-2">
                  <IconWrapper className="text-green-500">
                    <CheckIcon />
                  </IconWrapper>
                  <span className="text-base text-gray-900 dark:text-gray-100">
                    {t("send_in_user_timezone")}
                  </span>
                </div>
              )}
            </div>
          );

        case "template":
          return (
            <div className="space-y-6">
              <div>
                <h3 className="text-sm font-medium text-gray-500 dark:text-gray-400">
                  {t("template_type")}
                </h3>
                <p className="mt-1 text-base text-gray-900 dark:text-gray-100">
                  {item.config.template.type}
                </p>
              </div>
              {item.config.template.data && (
                <>
                  <div>
                    <h3 className="text-sm font-medium text-gray-500 dark:text-gray-400">
                      {t("subject")}
                    </h3>
                    <p className="mt-1 text-base text-gray-900 dark:text-gray-100">
                      {item.config.template.data.subject}
                    </p>
                  </div>
                  <div>
                    <h3 className="text-sm font-medium text-gray-500 dark:text-gray-400">
                      {t("from")}
                    </h3>
                    <p className="mt-1 text-base text-gray-900 dark:text-gray-100">
                      {`${item.config.template.data.from.name} <${item.config.template.data.from.email}>`}
                    </p>
                  </div>
                  <div>
                    <h3 className="text-sm font-medium text-gray-500 dark:text-gray-400">
                      {t("content")}
                    </h3>
                    {item.config.template.type === "email" ? (
                      <div
                        className="mt-2 p-4 bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg"
                        dangerouslySetInnerHTML={{
                          __html: item.config.template.data.html,
                        }}
                      />
                    ) : (
                      <p className="mt-1 text-base text-gray-900 dark:text-gray-100 font-mono">
                        {item.config.template.data.text}
                      </p>
                    )}
                  </div>
                </>
              )}
            </div>
          );

        default:
          return null;
      }
    };

    return (
      <div className="fixed inset-0 bg-white dark:bg-gray-900 z-50 overflow-auto">
        {/* Header */}
        <div className="sticky top-0 bg-white dark:bg-gray-900 border-b border-gray-200 dark:border-gray-800">
          <div className="px-6 py-4 flex items-center justify-between">
            <div className="flex items-center gap-4">
              <Button size="regular" variant="secondary" onClick={onClose}>
                <IconWrapper>
                  <HiArrowLeft />
                </IconWrapper>
              </Button>
              <h2 className="text-xl font-semibold text-gray-900 dark:text-gray-100">
                {`${
                  item.type.charAt(0).toUpperCase() + item.type.slice(1)
                } Details`}
              </h2>
            </div>
            <div className="flex items-center gap-4">
              {/* {item.type === "template" &&
                item.config.template?.type === "email" && (
                  <ImageQualityToggle
                    value={imageQuality}
                    onChange={setImageQuality}
                  />
                )} */}
              <Button
                size="regular"
                variant="secondary"
                onClick={() => setShowJson(!showJson)}
              >
                {showJson ? t("hide_json") : t("show_json")}
              </Button>
            </div>
          </div>
        </div>

        {/* Content */}
        <div className="p-6">
          <div className="space-y-6">
            {showJson ? (
              <pre className="bg-gray-50 dark:bg-gray-800 p-4 rounded-lg overflow-auto">
                <code className="text-sm text-gray-600 dark:text-gray-400">
                  {JSON.stringify(item.config, null, 2)}
                </code>
              </pre>
            ) : (
              renderFormattedDetails()
            )}

            {/* Error Message */}
            {item.error && (
              <div className="bg-red-50 dark:bg-red-900/20 border border-red-200 dark:border-red-800 rounded-lg p-4">
                <div className="flex items-center gap-2 text-red-600 dark:text-red-400">
                  <IconWrapper>
                    <ExclamationIcon />
                  </IconWrapper>
                  <span>{item.error}</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const StepActions = ({
    item,
    itemKey,
    onRetry,
    onRegenerate,
    isCompleted,
    onShowDetails,
  }: StepActionsProps) => {
    const { t } = useTranslation();
    const [showPromptDialog, setShowPromptDialog] = useState(false);
    const [prompt, setPrompt] = useState("");
    const [imageQuality, setImageQuality] = useState<ImageQuality>("SD");

    if (isCompleted) {
      return null;
    }

    return (
      <>
        <div className="flex items-center gap-2">
          <button
            type="button"
            onClick={onShowDetails}
            className="p-1 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300 rounded-md hover:bg-gray-100 dark:hover:bg-gray-800"
          >
            <span className="w-4 h-4 flex items-center justify-center">
              <HiInformationCircle />
            </span>
          </button>
        </div>

        {showPromptDialog && (
          <Dialog
            open={showPromptDialog}
            onClose={(isOpen: boolean) => setShowPromptDialog(isOpen)}
            title={t("modify_step")}
          >
            <div className="space-y-4">
              {/* {item.type === "template" &&
                item.config.template?.type === "email" && (
                  <ImageQualityToggle
                    value={imageQuality}
                    onChange={setImageQuality}
                  />
                )} */}
              <textarea
                className="w-full h-32 px-3 py-2 text-sm border rounded-lg focus:ring-2 focus:ring-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-gray-100"
                value={prompt}
                onChange={(e) => setPrompt(e.target.value)}
                placeholder={t("enter_modification_prompt")}
              />
              <div className="flex justify-end gap-2">
                <button
                  type="button"
                  onClick={() => setShowPromptDialog(false)}
                  className="px-4 py-2 text-sm font-medium text-gray-700 dark:text-gray-300 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-600 rounded-md hover:bg-gray-50 dark:hover:bg-gray-700"
                >
                  {t("cancel")}
                </button>
                <button
                  type="button"
                  onClick={() => {
                    onRegenerate(
                      parseInt(itemKey, 10),
                      prompt,
                      imageQuality,
                      selectedModel
                    );
                    setShowPromptDialog(false);
                  }}
                  className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 dark:bg-blue-500 dark:hover:bg-blue-600"
                >
                  {t("apply")}
                </button>
              </div>
            </div>
          </Dialog>
        )}
      </>
    );
  };

  const handleRegenerate = async (
    stepIndex: number,
    prompt?: string,
    imageQuality: ImageQuality = "SD",
    model: string = "gpt-4o"
  ) => {
    setIsLoading(true);
    try {
      const result = await api.insights.regenerateStep(
        location.id,
        insight.id,
        {
          stepIndex,
          model,
          prompt,
          imageQuality: imageQuality as string,
        }
      );
      setSteps(result.steps);
    } catch (error) {
      console.error("Error regenerating step:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-col h-full bg-white dark:bg-gray-900 rounded-lg shadow-sm">
      {/* Header Section */}
      <div className="px-6 py-4 border-b border-gray-200 dark:border-gray-800">
        <div className="flex items-center justify-between">
          <h2 className="text-xl font-semibold text-gray-900 dark:text-gray-100">
            {t("description")}
          </h2>
        </div>
        <p className="mt-2 text-sm text-gray-600 dark:text-gray-400 leading-relaxed">
          {insight.description}
        </p>
      </div>

      {/* Main Content */}
      <div className="flex-1 overflow-auto">
        {!plan ? (
          <div className="p-6">
            <div className="space-y-6">
              <div className="bg-blue-50 dark:bg-blue-900/20 rounded-lg p-6">
                <h4 className="text-md font-medium text-gray-900 dark:text-gray-100 mb-4">
                  {t("suggested_automation")}
                </h4>
                <ul className="space-y-3">
                  {insight.actions.map((action: string, index: number) => (
                    <li key={index} className="flex items-start">
                      <span className="flex-shrink-0 w-6 h-6 rounded-full bg-blue-100 dark:bg-blue-800 flex items-center justify-center text-blue-600 dark:text-blue-300 text-sm mr-3 mt-0.5">
                        {index + 1}
                      </span>
                      <span className="text-sm text-gray-700 dark:text-gray-300">
                        {action}
                      </span>
                    </li>
                  ))}
                </ul>
              </div>

              <div className="flex items-center gap-3 justify-end">
                <div className="w-48">
                  <SingleSelect
                    value={selectedModel}
                    onChange={(option: any) => setSelectedModel(option.value)}
                    options={MODEL_OPTIONS}
                    size="small"
                  />
                </div>
                {/* <ImageQualityToggle
                  value={imageQuality}
                  onChange={setImageQuality}
                /> */}
                <button
                  type="button"
                  onClick={handleGenerate}
                  disabled={isLoading}
                  className={clsx(
                    "px-4 py-2 text-sm font-medium rounded-md",
                    "text-white bg-blue-600 hover:bg-blue-700",
                    "dark:bg-blue-500 dark:hover:bg-blue-600",
                    "disabled:opacity-50 disabled:cursor-not-allowed"
                  )}
                >
                  {isLoading ? (
                    <Spinner size="small" className="text-white" />
                  ) : (
                    t("generate_automation_plan")
                  )}
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="p-6 space-y-6">
            {/* Plan Execution State */}
            <div className="relative pt-1">
              <div className="flex items-center justify-between mb-2">
                <span className="text-xs font-semibold text-blue-600 dark:text-blue-400">
                  {`${Math.round(progress)}% ${t("complete")}`}
                </span>
                <span className="text-xs font-semibold text-gray-600 dark:text-gray-400">
                  {`${completedSteps.length}/${
                    Object.keys(plan.items).length
                  } ${t("steps")}`}
                </span>
              </div>
              <motion.div
                className="h-2 bg-blue-100 dark:bg-blue-900/30 rounded-full overflow-hidden"
                initial={{ width: 0 }}
                animate={{ width: "100%" }}
                transition={{ duration: 0.5 }}
              >
                <motion.div
                  className="h-full bg-blue-500 dark:bg-blue-400 rounded-full"
                  initial={{ width: 0 }}
                  animate={{ width: `${progress}%` }}
                  transition={{ duration: 0.5 }}
                />
              </motion.div>
            </div>

            {/* Steps */}
            <div className="space-y-4">
              {Object.entries(plan.items).map(([itemKey, item], index) => {
                const status = getStepStatus(index);
                const typedItem = item as AutomationItem;
                return (
                  <motion.div
                    key={itemKey}
                    className={`
                      rounded-lg border overflow-hidden
                    ${
                      status === "in-progress"
                        ? "border-blue-200 dark:border-blue-800"
                        : ""
                    }
                    ${
                      status === "completed"
                        ? "border-green-200 dark:border-green-800"
                        : ""
                    }
                    ${
                      status === "error"
                        ? "border-red-200 dark:border-red-800"
                        : ""
                    }
                      ${
                        status === "pending" || status === "queued"
                          ? "border-gray-200 dark:border-gray-700"
                          : ""
                      }
                  `}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: index * 0.1 }}
                  >
                    <div
                      className={`
                        p-4
                        ${
                          status === "in-progress"
                            ? "bg-blue-50 dark:bg-blue-900/20"
                            : ""
                        }
                        ${
                          status === "completed"
                            ? "bg-green-50 dark:bg-green-900/20"
                            : ""
                        }
                        ${
                          status === "error"
                            ? "bg-red-50 dark:bg-red-900/20"
                            : ""
                        }
                        ${
                          status === "pending" || status === "queued"
                            ? "bg-gray-50 dark:bg-gray-800"
                            : ""
                        }
                      `}
                    >
                      <div className="flex items-center gap-4">
                        <div className="flex-shrink-0 w-8 h-8 rounded-full flex items-center justify-center bg-white dark:bg-gray-900 shadow-sm">
                          {getStepIcon(status)}
                        </div>
                        <div className="flex-1 min-w-0">
                          <h4 className="text-sm font-medium text-gray-900 dark:text-gray-100">
                            {`${
                              typedItem.type.charAt(0).toUpperCase() +
                              typedItem.type.slice(1)
                            }`}
                          </h4>
                          <p className="text-sm text-gray-600 dark:text-gray-400 truncate">
                            {typedItem.config[typedItem.type]?.name}
                          </p>
                        </div>
                        <StepActions
                          item={typedItem}
                          itemKey={itemKey}
                          onRetry={async (key: string) => {
                            if (!plan) return;
                            try {
                              const resourceData = await executeItem(
                                plan.items[key] as AutomationItem,
                                key,
                                createdResourceIds
                              );
                              if (resourceData) {
                                createdResourceIds.set(key, resourceData);
                                setCompletedSteps((prev) => [...prev, index]);
                              }
                            } catch (error) {
                              console.error(
                                `Error retrying item ${key}:`,
                                error
                              );
                            }
                          }}
                          onRegenerate={handleRegenerate}
                          isCompleted={
                            completedSteps.length ===
                            Object.keys(plan?.items || {}).length
                          }
                          onShowDetails={() =>
                            setSelectedStep({ key: itemKey, item: typedItem })
                          }
                        />
                      </div>
                    </div>
                  </motion.div>
                );
              })}
            </div>
          </div>
        )}

        {/* Error Message */}
        {error && (
          <div className="px-6 pb-6">
            <div className="text-sm text-red-600 dark:text-red-400 p-4 bg-red-50 dark:bg-red-900/20 rounded-lg border border-red-200 dark:border-red-800">
              <div className="flex items-center gap-2">
                <div className="w-5 h-5">
                  <ExclamationIcon />
                </div>
                <span>{error}</span>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Footer */}
      {plan && (
        <div className="px-6 py-4 border-t border-gray-200 dark:border-gray-800 bg-gray-50 dark:bg-gray-900">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-3">
              {!isExecuting && (
                <>
                  <button
                    type="button"
                    onClick={handleGenerate}
                    disabled={isLoading}
                    className={clsx(
                      "px-4 py-2 text-sm font-medium rounded-md",
                      "text-gray-700 bg-white border border-gray-300",
                      "hover:bg-gray-50 dark:text-gray-300",
                      "dark:bg-gray-800 dark:border-gray-600",
                      "dark:hover:bg-gray-700 disabled:opacity-50",
                      "disabled:cursor-not-allowed"
                    )}
                  >
                    {isLoading ? (
                      <Spinner
                        size="small"
                        className="text-gray-700 dark:text-gray-300"
                      />
                    ) : (
                      t("regenerate_plan")
                    )}
                  </button>
                  {completedSteps.length === 0 && (
                    <button
                      type="button"
                      onClick={executeSteps}
                      className={clsx(
                        "px-4 py-2 text-sm font-medium rounded-md",
                        "text-white bg-blue-600 hover:bg-blue-700",
                        "dark:bg-blue-500 dark:hover:bg-blue-600"
                      )}
                    >
                      {t("create_automation")}
                    </button>
                  )}
                </>
              )}
            </div>
            {isExecuting && (
              <span className="flex items-center text-blue-600 dark:text-blue-400">
                <Spinner size="small" className="mr-2" />
                {t("executing_steps")}
              </span>
            )}
          </div>
        </div>
      )}

      {/* Step Details Modal */}
      {selectedStep && plan && (
        <>
          {/* Dark overlay */}
          <div
            className="fixed inset-0 bg-black/50 z-40"
            onClick={() => setSelectedStep(null)}
          />

          {/* Modal */}
          <div
            className="fixed inset-0 z-50 overflow-auto"
            style={{ position: "fixed", overflowY: "auto" }}
          >
            <div className="min-h-screen flex items-center justify-center p-4">
              <div className="bg-white dark:bg-gray-900 w-full max-w-4xl rounded-lg shadow-xl relative">
                {/* Close button */}
                <button
                  onClick={() => setSelectedStep(null)}
                  className="absolute top-4 right-4 p-2 text-gray-400 hover:text-gray-500 dark:text-gray-500 dark:hover:text-gray-400"
                >
                  <span className="sr-only">{t("close")}</span>
                  <svg
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>

                {/* Header */}
                <div className="sticky top-0 bg-white dark:bg-gray-900 border-b border-gray-200 dark:border-gray-800 rounded-t-lg">
                  <div className="px-6 py-4 flex items-center">
                    <div className="flex items-center gap-4">
                      <Button
                        size="regular"
                        variant="secondary"
                        onClick={() => setSelectedStep(null)}
                      >
                        <IconWrapper>
                          <HiArrowLeft />
                        </IconWrapper>
                      </Button>
                      <h2 className="text-xl font-semibold text-gray-900 dark:text-gray-100">
                        {`${
                          selectedStep.item.type.charAt(0).toUpperCase() +
                          selectedStep.item.type.slice(1)
                        } Details`}
                      </h2>
                    </div>
                    <div className="flex items-center gap-2 ml-auto mr-8">
                      {selectedStep.item.error && (
                        <Button
                          size="regular"
                          variant="secondary"
                          onClick={async () => {
                            try {
                              const resourceData = await executeItem(
                                plan.items[selectedStep.key] as AutomationItem,
                                selectedStep.key,
                                createdResourceIds
                              );
                              if (resourceData) {
                                const index = Object.keys(plan.items).indexOf(
                                  selectedStep.key
                                );
                                createdResourceIds.set(
                                  selectedStep.key,
                                  resourceData
                                );
                                setCompletedSteps((prev) => [...prev, index]);
                              }
                            } catch (error) {
                              console.error(
                                `Error retrying item ${selectedStep.key}:`,
                                error
                              );
                            }
                          }}
                        >
                          <IconWrapper>
                            <HiArrowPath />
                          </IconWrapper>
                        </Button>
                      )}
                      <Button
                        size="regular"
                        variant="secondary"
                        onClick={() => {
                          const dialog =
                            document.querySelector("#prompt-dialog");
                          if (dialog) {
                            dialog.classList.remove("hidden");
                          }
                        }}
                      >
                        <IconWrapper>
                          <HiPencil />
                        </IconWrapper>
                      </Button>
                    </div>
                  </div>
                </div>

                {/* Content */}
                <div className="p-6">
                  <div className="space-y-6">
                    {/* Configuration */}
                    <div className="space-y-6">
                      {/* Email Preview */}
                      {selectedStep.item.type === "template" &&
                        selectedStep.item.config.template?.type === "email" && (
                          <div>
                            <div className="bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg overflow-hidden">
                              {/* Email Header */}
                              <div className="border-b border-gray-200 dark:border-gray-700 p-4 space-y-2">
                                <div className="flex items-center gap-2">
                                  <span className="text-sm font-medium text-gray-500 dark:text-gray-400">
                                    From:
                                  </span>
                                  <span className="text-sm text-gray-900 dark:text-gray-100">
                                    {
                                      selectedStep.item.config.template.data
                                        .from?.name
                                    }{" "}
                                    &lt;
                                    {
                                      selectedStep.item.config.template.data
                                        .from?.email
                                    }
                                    &gt;
                                  </span>
                                </div>
                                {selectedStep.item.config.template.data
                                  .reply_to && (
                                  <div className="flex items-center gap-2">
                                    <span className="text-sm font-medium text-gray-500 dark:text-gray-400">
                                      Reply-To:
                                    </span>
                                    <span className="text-sm text-gray-900 dark:text-gray-100">
                                      {
                                        selectedStep.item.config.template.data
                                          .reply_to
                                      }
                                    </span>
                                  </div>
                                )}
                                <div className="flex items-center gap-2">
                                  <span className="text-sm font-medium text-gray-500 dark:text-gray-400">
                                    Subject:
                                  </span>
                                  <span className="text-sm text-gray-900 dark:text-gray-100">
                                    {
                                      selectedStep.item.config.template.data
                                        .subject
                                    }
                                  </span>
                                </div>
                                {selectedStep.item.config.template.data
                                  .preheader && (
                                  <div className="flex items-center gap-2">
                                    <span className="text-sm font-medium text-gray-500 dark:text-gray-400">
                                      Preheader:
                                    </span>
                                    <span className="text-sm text-gray-900 dark:text-gray-100">
                                      {
                                        selectedStep.item.config.template.data
                                          .preheader
                                      }
                                    </span>
                                  </div>
                                )}
                              </div>
                              {/* Email Content */}
                              <div className="p-4">
                                <div
                                  className="prose dark:prose-invert max-w-none"
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      selectedStep.item.config.template.data
                                        .html,
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        )}

                      {/* Collapsible JSON */}
                      <div>
                        <button
                          onClick={() => setShowJson(!showJson)}
                          className="flex items-center gap-2 text-sm font-medium text-gray-900 dark:text-gray-100 hover:text-gray-700 dark:hover:text-gray-300"
                        >
                          {showJson ? (
                            <span className="w-4 h-4">
                              <ChevronUpIcon />
                            </span>
                          ) : (
                            <span className="w-4 h-4">
                              <ChevronDownIcon />
                            </span>
                          )}
                          {showJson ? "Hide JSON" : "Show JSON"}
                        </button>
                        {showJson && (
                          <div className="mt-2">
                            <pre className="bg-gray-50 dark:bg-gray-800 p-4 rounded-lg overflow-auto max-h-96">
                              <code className="text-sm text-gray-600 dark:text-gray-400">
                                {JSON.stringify(
                                  selectedStep.item.config,
                                  null,
                                  2
                                )}
                              </code>
                            </pre>
                          </div>
                        )}
                      </div>
                    </div>

                    {/* Error Message */}
                    {selectedStep.item.error && (
                      <div className="mt-6 bg-red-50 dark:bg-red-900/20 border border-red-200 dark:border-red-800 rounded-lg p-4">
                        <div className="flex items-center gap-2 text-red-600 dark:text-red-400">
                          <IconWrapper>
                            <ExclamationIcon />
                          </IconWrapper>
                          <span>{selectedStep.item.error}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                {/* Prompt Dialog */}
                <div
                  id="prompt-dialog"
                  className="hidden fixed inset-0 bg-black/50 flex items-start justify-center pt-20"
                >
                  <div className="bg-white dark:bg-gray-800 rounded-lg p-4 w-96 space-y-4">
                    <h3 className="text-lg font-medium text-gray-900 dark:text-gray-100">
                      {t("modify_step")}
                    </h3>
                    <textarea
                      className="w-full h-32 px-3 py-2 text-sm border rounded-lg focus:ring-2 focus:ring-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-gray-100"
                      placeholder={t("enter_modification_prompt")}
                    />
                    <div className="flex justify-end gap-2">
                      <Button
                        size="regular"
                        variant="secondary"
                        onClick={() => {
                          const dialog =
                            document.querySelector("#prompt-dialog");
                          if (dialog) {
                            dialog.classList.add("hidden");
                          }
                        }}
                      >
                        {t("cancel")}
                      </Button>
                      <Button
                        size="regular"
                        variant="primary"
                        onClick={() => {
                          const dialog =
                            document.querySelector("#prompt-dialog");
                          const textarea = dialog?.querySelector("textarea");
                          if (dialog && textarea) {
                            handleRegenerate(
                              parseInt(selectedStep.key, 10),
                              textarea.value
                            );
                            dialog.classList.add("hidden");
                          }
                        }}
                      >
                        {t("apply")}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
