import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PageContent, Button } from "../../ui";
import { SingleSelect } from "../../ui/form/SingleSelect";
import api from "../../api";
import { Insight } from "../../types";
import { LocationContext } from "../../contexts";
import InsightCard from "../../ui/InsightCard";
import Spinner from "../../ui/Spinner";

const MODEL_OPTIONS = [
  { value: "gpt-4o", label: "GPT-4o" },
  { value: "gpt-4o-mini", label: "GPT-4o Mini" },
];

export default function Insights() {
  const { t } = useTranslation();
  const [insights, setInsights] = useState<Insight[]>([]);
  const [loading, setLoading] = useState(true);
  const [location] = useContext(LocationContext);
  const [selectedModel, setSelectedModel] = useState("gpt-4o");

  useEffect(() => {
    loadInsights();
  }, []);

  const loadInsights = async () => {
    setLoading(true);
    try {
      const data = await api.insights.get(location.id);
      setInsights(data);
    } catch (error) {
      console.error("Error loading insights:", error);
    }
    setLoading(false);
  };

  const handleGenerate = async () => {
    try {
      setLoading(true);
      await api.insights.generate(location.id, selectedModel);
      await loadInsights(); // Refresh the list after generation
      setLoading(false);
    } catch (error) {
      console.error("Error generating insights:", error);
      setLoading(false);
    }
  };

  return (
    <PageContent
      title={t("ai_insights")}
      actions={
        <div className="flex items-center gap-3">
          <SingleSelect
            value={selectedModel}
            onChange={(option: any) => setSelectedModel(option.value)}
            options={MODEL_OPTIONS}
            disabled={loading}
            size="small"
          />
          <Button disabled={loading} onClick={handleGenerate}>
            {loading ? <Spinner size="small" /> : t("generate_insights")}
          </Button>
        </div>
      }
    >
      <div className="space-y-4">
        {insights.map((insight) => (
          <InsightCard
            key={insight.id}
            insight={insight}
            onRefresh={loadInsights}
          />
        ))}
      </div>
    </PageContent>
  );
}
