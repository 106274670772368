import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./i18n";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ChatWidget from "./ChatWidget";
import { CartProvider } from "./ChatWidget/CartContext";

const router = createBrowserRouter([
  {
    path: "/widget/:locationId",
    element: (
      <CartProvider>
        <ChatWidget skipVerify />
      </CartProvider>
    ),
  },
  {
    path: "*",
    element: <App />,
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <StrictMode>
    <App />
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
