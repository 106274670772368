import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { DownloadIcon } from "../icons";
import Button from "../Button";

const Container = styled.div`
  margin-bottom: 20px;
`;

const FieldList = styled.ul`
  margin: 10px 0;
  padding-left: 20px;
`;

const Field = styled.li<{ required?: boolean }>`
  margin: 5px 0;
  color: ${(props) => (props.required ? "#000" : "#666")};
  &::before {
    content: "${(props) => (props.required ? "* " : "")}";
    color: #d32f2f;
  }
`;

const Note = styled.p`
  font-size: 0.9em;
  color: #666;
  margin: 10px 0;
`;

interface UploadField {
  name: string;
  required?: boolean;
  description?: string;
}

interface UploadInstructionsProps {
  fields: UploadField[];
  onDownloadExample?: () => void;
  acceptedFormat?: string;
}

export default function UploadInstructions({
  fields,
  onDownloadExample,
  acceptedFormat = ".csv",
}: UploadInstructionsProps) {
  const { t } = useTranslation();

  return (
    <Container>
      <p>{t("upload_product_instructions")}</p>

      {onDownloadExample && (
        <Button
          variant="secondary"
          size="small"
          icon={<DownloadIcon />}
          onClick={onDownloadExample}
        >
          {t("download_example")}
        </Button>
      )}
    </Container>
  );
}
